import './Faq.css';
import { Desktop, Mobile, MobileFooter } from '../../common/Common';
import Panel, { isShiftLeft } from 'common/panel/Panel';
import { Link, useLocation } from 'react-router-dom';
import Watermark from 'common/Watermark';

const Faq = () => {
  return (
    <>
      <Desktop><FaqDesktop/></Desktop>
      <Mobile><FaqMobile/></Mobile> 
    </>
  );
}

const FaqContent = () => {
  return (
    <>
      <div className='faq-item'>
        <div className='faq__question'>Where are you located?</div>
        I am a junior artist located in the bay area, ca.
      </div>
      <div className='faq-item'>
        <div className='faq__question'>What do you tattoo?</div>
        I focus on fine-line, botanical illustration. I currently do sketch-style black linework only. I’d love to tattoo more california native plants, video game/anime/fantasy plants!
      </div>
      <div className='faq-item'>
        <div className='faq__question'>What parts of the body do you tattoo?</div>
        I am focusing on arms and legs. I do NOT tattoo hands, feet, head, neck, and groin. If you have another placement idea, please run it by me.
      </div>
      <div className='faq-item'>
        <div className='faq__question'>What are your rates?</div>
        my rates starting May 2024 is $180/hr. I will provide an accurate price during our email conversation. Please note I often cap the cost at the amount of time I think is reasonable to complete your design request, and I do not charge for any overtime.
      </div>
      <div className='faq-item'>
        <div className='faq__question'>Do you take custom requests?</div>
        yes! (and also for flash I am pretty open to minor changes, just let me know!)
      </div>
      <div className='faq-item'>
        <div className='faq__question'>What is your booking process like?</div>
        My current booking process is as follows:
        <ul>
          <li>every other month, I email my mailing list with my booking form.</li>
          <li>if there is availablity after a few days, I will open up books to all, found on my <Link className='custom-link' to={'/booking'}>booking page</Link>.</li>
          <li>I will try to respond to your email within 48 hours.</li>
          <li>If we are a good fit, we will reserve an appointment time with a deposit (venmo/zelle) of either $50 (flash) or $100 (custom).</li>
        </ul>
      </div>
      <div className='faq-item'>
        <div className='faq__question'>Are your tattoo materials vegan?</div>
        To my best ability, yes. I am dedicated to a harm-reduction process and that includes the materials I work with. That means I use plant-based, cruelty-free products when I can source them and without compromising sanitary requirements.
      </div>
      <div className='faq-item'>
        <div className='faq__question'>What types of payment do you accept?</div>
        <ul>
          <li>
            deposit: I accept venmo or zelle (or cash if we had an in person consultation).
          </li>
          <li>
            tattoo appointment: I accept cash only the day of the appointment.
          </li>
        </ul>
      </div>
      <div className='faq-item'>
        <div className='faq__question'>How should I prepare for my appointment?</div>
          (full pre-care can be found on the <Link className='custom-link' to={'/care'}>care page</Link>, here’s the tl;dr) Moisturize your tattoo location for the two weeks leading up to your appointment, and stay hydrated! The night before, please get good rest and refrain from too much alcohol. Come to your appointment after having a nice meal, and bring an ID and a mask. No guests are strongly preferred.
      </div>
      <div className='faq-item'>
        <div className='faq__question'>What is your rescheduling/cancellation policy?</div>
        my rescheduling/cancellation policy is in the works (because it's really flex--except for no shows). please communicate with me as early in advance if you anticipate a change in the appointment date!
      </div>
      <div className='faq-item'>
        <div className='faq__question'>What is your touch-up policy?</div>
        I do free touch-ups within 3 months of the tattoo appointment (after the tattoo is healed for 3-4 weeks). Please email me (<Link className='custom-link' to='mailto:wistaria.ink@gmail.com'>wistaria.ink@gmail.com</Link>) mentioning “touch-up” in the subject line, and include a photo of your tattoo.
      </div>
    </>
  )
}

const FaqDesktop = () => {
  const location = useLocation();
  return (
    <>
      <Watermark />
      <Panel />
      <div 
        id='faq__page' 
        className={`desktop-main desktop-main__fade-in${isShiftLeft(location) ? '-shift' : ''}`}
      >
        <div className='desktop-main__content'>
          <FaqContent />
        </div>
      </div>
    </>
  )
}

const FaqMobile = () => {
  return (
    <>
      <Watermark />
      <Panel />
      <div id='faq__page' className='mobile-main'>
        <div className='mobile-main__content'>
          <FaqContent />
        </div>
      </div>
      <MobileFooter />
    </>
  )
}

export default Faq;
