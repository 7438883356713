import './Home.css';
import logo from '../../assets/logo.png';
import { Desktop, Mobile, MobileFooter } from '../../common/Common';
import Panel, { isShiftRight } from 'common/panel/Panel';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <Desktop><HomeDesktop/></Desktop>
      <Mobile><HomeMobile/></Mobile>
    </>
  );
}

const HomeContent = () => {
  return (
    <>
      <img className='home-main__logo' src={logo} alt='logo' />
      <p> wih•star•ee•uh </p>
      <p> junior artist based in the bay area, CA. please sign up for my mailing list to know when I open books. thanks for stopping by ˙ᵕ˙
      </p>
    </>
  )
}

const HomeDesktop = () => {
  const location = useLocation();
  return (
    <>
      <Panel />
      <div 
        id='desktop-home' 
        className={`desktop-main desktop-main__fade-in${isShiftRight(location) ? '-shift' : ''}`}
      >
        <div className='desktop-main__content'>
          <HomeContent />
          <MailingList />
        </div>
      </div>
    </>
  )
}

const HomeMobile = () => {
  return (
    <>
      <Panel />
      <div id='mobile-home' className='mobile-main'>
        <div className='mobile-main__content'>
          <HomeContent />
          <MailingList />
        </div>
      </div>
      <MobileFooter />
    </>
  )
}

const MailingList = () => {
  return (
    <MailchimpSubscribe 
      url={process.env.REACT_APP_MAILCHIMP_URL}
      render={({ subscribe, status, message }) => (
        <CustomMailchimpForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  );
}

const CustomMailchimpForm = ({ status, message, onValidated}) => {
  const [email, setEmail] = useState('');

  console.log("status:", status)
  console.log("status type:", typeof status)

  useEffect(() => {
    if(status === 'success') {
      setEmail('');
    }
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email && email.indexOf('@') > -1 && 
      onValidated({
        EMAIL: email
      })
  }

  return (
    <>
      <p><span className='mailing-form__text'>get mail from me </span> 📬</p>
      <form className='mc__form' onSubmit={(e) => handleSubmit(e)}>
        <div className='mc__field-container'>
          <input 
            className='mc__input'
            onChange={(e) => setEmail(e.target.value)} 
            placeholder='email'
            type='email'
            value={email}
            required
          />
          <input
            className='mc__submit-button'
            type='submit'
            disabled={!Boolean(email.indexOf('@') > -1)}
            value='submit'
          />
        </div>
      </form>
      { status === 'success' &&
        <p className='mc__status-text mc__success-text'>{String(message).toLocaleLowerCase()}</p>
      }
      { status === 'error' &&
        <p className='mc__status-text mc__error-text'>{String(message).toLocaleLowerCase()}</p>
      }
    </>
  )
}

export default Home;
