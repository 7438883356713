import './About.css';
import { Desktop, Mobile, MobileFooter } from '../../common/Common';
import Panel, { isShiftLeft } from 'common/panel/Panel';
import Watermark from 'common/Watermark';
import profilePic from '../../assets/fb.jpg';
import { useLocation } from 'react-router-dom';

const About = () => {
  return (
    <>
      <Desktop><AboutDesktop/></Desktop>
      <Mobile><AboutMobile/></Mobile> 
    </>
  );
}

const AboutContent = () => {
  return (
    <div className='about-content'>
      <img className='profile-pic' width={200} height={200} src={profilePic} alt='profile pic' />
      <p>
        I am a chinese-american artist, born and raised in unceded Ohlone territory (bay area). currently, I work part-time as a junior tattoo artist in the bay area, and part-time in the range ecology field. my focus in botanical illustration comes from getting to meet so many beautiful flora across the california floristic province. I wish to bring our experiences with plants to stories and affirmations on the body. I also want to join my peers in creating a safe, accessible, and fun tattoo experience! 
        along with all things planty, I enjoy dancing, sewing, playing video games, and hanging out with my doggo and bun friends ˙ᵕ˙
      </p>
    </div>
  )
}

const AboutDesktop = () => {
  const location = useLocation();
  return (
    <>
      <Watermark />
      <Panel />
      <div 
        id='desktop-about' 
        className={`desktop-main desktop-main__fade-in${isShiftLeft(location) ? '-shift' : ''}`}
      >
        <div className='desktop-main__content'>
          <AboutContent />
        </div>
      </div>
    </>
  )
}

const AboutMobile = () => {
  return (
    <>
      <Watermark />
      <Panel />
      <div className='mobile-main'>
        <div className='mobile-main__content'>
          <AboutContent />
        </div>
      </div>
      <MobileFooter />
    </>
  )
}

export default About;
